<template>
  <div class="service">
    <div class="logo">
      <div class="banner-font">
        <div class="font-english">SPORTS BUSINESS EDITION APP</div>
        <div class="font-chinese">运动官商家版APP</div>
        <div class="font-behavior">
          <div
            class="font-understand"
            @click="
              $router.push({
                path: `/service/one${Math.floor(Math.random() * 100)}`,
              })
            "
          >
            了解商家版
          </div>
          <div
            class="font-join"
            @click="
              $router.push({
                path: `/service/Three${Math.floor(Math.random() * 100)}`,
              })
            "
          >
            加入商家版
          </div>
        </div>
      </div>
      <div class="font-download">
        <el-tooltip
          placement="top"
          effect="light"
          transition="el-zoom-in-center"
        >
          <div slot="content" style="width: 0.8rem; height: 0.8rem">
            <el-image
              :src="require('../assets/img/businessQrcode.png')"
            ></el-image>
          </div>
          <div class="font-download-apple"></div>
        </el-tooltip>
        <el-tooltip
          placement="top"
          effect="light"
          transition="el-zoom-in-bottom"
        >
          <div slot="content" style="width: 0.8rem; height: 0.8rem">
            <el-image
              :src="require('../assets/img/businessQrcode.png')"
            ></el-image>
          </div>
          <div class="font-download-android"></div>
        </el-tooltip>
      </div>
      <div class="mode"></div>
    </div>
    <div class="business" :id="'one' + $route.params.id">
      <div class="business-content">
        <div class="business-content-left">
          <div class="business-content-left-title">运动官商家版APP</div>
          <div class="business-content-left-info">
            运动官致力为体育行业创建一种见效快，成本低的有偿分销网络。体
            育机构作为资源方可以将自己的课程、场地、教学视频等发布到平台，
            并附带推广佣金，借助平台大量朋友圈资源用户将其分享给周边的运
            动爱好者并获得佣金鼓励。
          </div>
          <div class="business-content-left-contrast">体育机构获客成本对比</div>
          <div class="business-content-left-table">
            <table border="1">
              <thead>
                <tr>
                  <th>推广平台</th>
                  <th>获客成本 (元)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>体育机构地推</td>
                  <td>800 ~ 1500</td>
                </tr>
                <tr>
                  <td>某某点评平台</td>
                  <td>500 ~ 1000</td>
                </tr>
                <tr>
                  <td>某短视频平台</td>
                  <td>120 ~ 200</td>
                </tr>
                <tr>
                  <td style="background: #8400ff">运动官商家版</td>
                  <td style="font-size: 0.1rem; padding: 0.02rem 0">10 ~ 50</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="business-content-left-download">
            <el-tooltip
              placement="top"
              effect="light"
              transition="el-zoom-in-center"
            >
              <div slot="content" style="width: 0.8rem; height: 0.8rem">
                <el-image
                  :src="require('../assets/img/businessQrcode.png')"
                ></el-image>
              </div>
              <div class="font-download-apple"></div>
            </el-tooltip>
            <el-tooltip
              placement="right"
              effect="light"
              transition="el-zoom-in-bottom"
            >
              <div slot="content" style="width: 0.8rem; height: 0.8rem">
                <el-image
                  :src="require('../assets/img/businessQrcode.png')"
                ></el-image>
              </div>
              <div class="font-download-android"></div>
            </el-tooltip>
          </div>
        </div>
        <div class="business-content-right">
          <div class="business-content-right-bright"></div>
          <div class="business-content-right-dark">
            <div class="business-content-right-mode"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="resource" :id="'Three' + $route.params.id">
      <div class="resource-content">
        <div class="resource-content-left">
          <div class="resource-content-left-title">资源官与推广官</div>
          <div class="resource-content-left-info">
            资源官｜代表体育机构、工作室、个人教练
            向平台提供课程、场地、视频教学的资源方
          </div>
          <div class="resource-content-left-contrast">
            推广官｜由平台拓展的拥有人脉、朋友圈、 群资源的庞大社交群体
          </div>
          <div class="resource-content-left-img"></div>
          <div class="resource-content-left-download">
            <div class="font-download-apple" @click="handleJoin(1)">
              加入资源官
            </div>
            <div class="font-download-android" @click="handleJoin(2)">
              加入推广官
            </div>
          </div>
        </div>
        <div class="resource-content-right">
          <div class="resource-content-right-bright"></div>
          <div class="resource-content-right-dark">
            <div class="resource-content-right-mode"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="Network" :id="'two' + $route.params.id">
      <div class="Network-content">
        <div class="Network-content-left">
          <div class="Network-content-left-title">一键生成小程序网店</div>
          <div class="Network-content-left-info">
            推广官一键生成体育网店0培训、0管理、0
            维护、微信分享周边的体育爱好者、轻松赚 取佣金
          </div>
          <div class="Network-content-left-img"></div>
          <el-tooltip
            placement="right"
            effect="light"
            transition="el-zoom-in-bottom"
          >
            <div slot="content" style="width: 1rem; height: 1rem">
              <el-image :src="require('../assets/img/index.jpg')"></el-image>
            </div>
            <div class="Network-content-left-download">前往体育网店</div>
          </el-tooltip>
        </div>
        <div class="Network-content-right">
          <div class="Network-content-right-bright"></div>
          <div class="Network-content-right-dark">
            <div class="Network-content-right-mode"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="coldplay">
      <div class="coldplay-content">
        <div class="coldplay-content-left">
          <div class="coldplay-content-left-title">酷玩+体育周周有安排</div>
          <div class="coldplay-content-left-info">
            每周去哪玩再也不发愁，边玩边体验不同体育运动<br />
          </div>
          <div class="coldplay-content-left-img"></div>

          <el-tooltip
            placement="right"
            effect="light"
            transition="el-zoom-in-bottom"
          >
            <div slot="content" style="width: 1rem; height: 1rem">
              <el-image :src="require('../assets/img/activity.jpg')"></el-image>
            </div>
            <div class="coldplay-content-left-download">了解酷玩运动</div>
          </el-tooltip>
        </div>
        <div class="coldplay-content-right">
          <div class="coldplay-content-right-bright"></div>
          <div class="coldplay-content-right-dark">
            <div class="coldplay-content-right-mode"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="download">
      <div class="download-content">
        <div class="download-content-left">
          <div class="download-content-left-info">
            <div class="iconlogo">
              <el-image
                class="iconlogoimg"
                :src="require('../assets/img/logo_white.png')"
              ></el-image>
              <span class="iconlogotext">运动官</span>
            </div>
            <div class="download-content-left-introduce">
              <span>运动生活&nbsp;&nbsp;&nbsp;快乐有我<br /></span>
              <span>科学运动引领者<br /></span>
            </div>
            <div class="download-content-left-mode">
              <el-tooltip
                placement="top"
                effect="light"
                transition="el-zoom-in-center"
              >
                <div slot="content" style="width: 0.8rem; height: 0.8rem">
                  <el-image
                    :src="require('../assets/img/sportQrcode.png')"
                  ></el-image>
                </div>
                <div class="download-content-left-mode-apple"></div>
              </el-tooltip>
              <el-tooltip
                placement="top"
                effect="light"
                transition="el-zoom-in-bottom"
              >
                <div slot="content" style="width: 0.8rem; height: 0.8rem">
                  <el-image
                    :src="require('../assets/img/sportQrcode.png')"
                  ></el-image>
                </div>
                <div class="download-content-left-mode-android"></div>
              </el-tooltip>
            </div>
            <div class="iconlogo" style="margin-top: 0.8rem">
              <el-image
                class="iconlogoimg"
                :src="require('../assets/img/logo_black.png')"
              ></el-image>
              <span class="iconlogotext">运动官商家版</span>
            </div>
            <div class="download-content-left-introduce">
              <span>垂直体育分销系统<br /></span>
              <span>科学运动引领者<br /></span>
            </div>
            <div class="download-content-left-mode">
              <el-tooltip
                placement="top"
                effect="light"
                transition="el-zoom-in-center"
              >
                <div slot="content" style="width: 0.8rem; height: 0.8rem">
                  <el-image
                    :src="require('../assets/img/businessQrcode.png')"
                  ></el-image>
                </div>
                <div class="download-content-left-mode-apple"></div>
              </el-tooltip>
              <el-tooltip
                placement="top"
                effect="light"
                transition="el-zoom-in-bottom"
              >
                <div slot="content" style="width: 0.8rem; height: 0.8rem">
                  <el-image
                    :src="require('../assets/img/businessQrcode.png')"
                  ></el-image>
                </div>
                <div class="download-content-left-mode-android"></div>
              </el-tooltip>
            </div>
          </div>
        </div>
        <div class="download-content-right">
          <div class="download-content-right-mode"></div>
        </div>
      </div>
    </div>
    <el-dialog
      title="资源官与推广官"
      :visible.sync="dialogVisible"
      width="800px"
      :before-close="handleClose"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="80px"
        class="demo-ruleForm"
        @submit.native.prevent
      >
        <el-form-item label="兴趣类型" prop="interestType">
          <el-radio-group v-model="ruleForm.interestType">
            <el-radio-button label="1">我对资源官感兴趣</el-radio-button>
            <el-radio-button label="2">我对推广官感兴趣</el-radio-button>
            <el-radio-button label="3">两者均有兴趣</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="联系方式" prop="contactInformation">
          <el-input
            v-model="ruleForm.contactInformation"
            placeholder="请留下您的联系方式，手机或微信号，我们将尽快与您联系。"
            size="small"
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >提 交</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { userInterestCollectionAdd } from "@/api/service";
import mixin from "@/api/mixin";
export default {
  mixins: [mixin],
  data() {
    return {
      tableData: [
        {
          name: "体育机构地推",
          value: "800 ~ 1500",
        },
        {
          name: "某某点评平台",
          value: "500 ~ 1000",
        },
        {
          name: "某短视频平台",
          value: "120 ~ 200",
        },
        {
          name: "运动官商家版",
          value: "10 ~ 50",
        },
      ],
      dialogVisible: false,
      ruleForm: {
        interestType: "1",
        contactInformation: "",
      },
      rules: {
        contactInformation: [
          { required: true, message: "请输入内容", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    handleJoin(type) {
      this.ruleForm = {
        interestType: type,
        contactInformation: "",
      };
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          userInterestCollectionAdd(this.ruleForm).then((res) => {
            if (res.data.code == 200) {
              this.$message.success("提交成功");
              this.dialogVisible = false;
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
@keyframes fadenum {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}
.service {
  width: 100%;
  .logo {
    width: 100%;
    height: 5.5rem;
    // height: 100%;
    position: relative;
    text-align: center;
    // background-size:contain;
    background: url("../assets/img/banner.jpg") no-repeat;
    background-size: 100% 100%;
    .mode {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #111;
      opacity: 0.4;
      z-index: 1;
    }
    .font-download {
      position: absolute;
      width: 2rem;
      // height: 0.3rem;
      // background-color: red;
      bottom: 0.4rem;
      right: 0.6rem;
      z-index: 2;
      // bottom: 0.2rem;
      display: flex;
      justify-content: space-between;
      .font-download-apple {
        width: 0.9rem;
        height: 0.25rem;
        background: url("../assets/img/u912.png") no-repeat center;
        background-size: 80% 80%;
        border: 1px solid #fff;
        border-radius: 5px;
        background-color: #111;
        cursor: pointer;
      }
      .font-download-android {
        width: 0.9rem;
        height: 0.25rem;
        background: url("../assets/img/u913.png") no-repeat center;
        background-size: 80% 80%;
        border: 1px solid #fff;
        border-radius: 5px;
        background-color: #111;
        cursor: pointer;
      }
    }
  }

  .banner-font {
    position: absolute;
    top: 50%;
    left: 50%;
    color: #fff;
    transform: translate(-50%, -50%);
    z-index: 2;
    .font-english {
      font-weight: 600;
      font-size: 0.2rem;
      animation: fadenum 4s 1;
      -webkit-animation: fadenum 4s 1;
      -moz-animation: fadenum 4s 1;
    }
    .font-chinese {
      margin-top: 0.2rem;
      font-size: 0.12rem;
      animation: fadenum 4s 1;
      -webkit-animation: fadenum 4s 1;
      -moz-animation: fadenum 4s 1;
    }
    .font-behavior {
      width: 2.5rem;
      height: 0.26rem;
      // background-color: red;
      margin: 1rem auto 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      line-height: 0.26rem;
      .font-understand {
        height: 100%;
        padding: 0 0.25rem;
        font-size: 0.08rem;
        background-color: #111;
        border: 1px solid #fff;
        border-radius: 10px;
        cursor: pointer;
      }
      .font-join {
        height: 100%;
        padding: 0 0.25rem;
        font-size: 0.08rem;
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0.1);

        border: 1px solid #fff;
        border-radius: 10px;
      }
      .font-join:hover {
        background-color: #111;
      }
    }
  }
  .business {
    width: 100%;
    // height: 5rem;
    background: url("../assets/img/output.gif") no-repeat;
    background-size: 100% 100%;
    overflow: hidden;
    .business-content {
      margin: 0.5rem 0.8rem;
      // height: 4rem;
      // background: red;
      display: flex;
      justify-content: space-between;
      .business-content-left {
        // flex: 1;
        width: 2rem;
        margin-left: 0.2rem;
        .business-content-left-title {
          font-size: 0.2rem;
          color: #fff;
        }
        .business-content-left-info {
          margin-top: 0.4rem;
          color: #fff;
          font-size: 0.08rem;
          line-height: 28px;
        }
        .business-content-left-contrast {
          margin-top: 0.4rem;
          color: #fff;
          font-size: 0.08rem;
        }
        .business-content-left-table {
          table {
            width: 100%;
            text-align: center;
            color: #fff;
            border: 1px solid #fff;
            margin-top: 0.2rem;
            font-size: 0.06rem;
            th,
            td {
              padding: 0.06rem 0;
            }
            td:nth-child(2n) {
              font-weight: 600;
            }
          }
        }
        .business-content-left-download {
          margin-top: 0.5rem;
          width: 100%;
          display: flex;
          justify-content: space-between;
          .font-download-apple {
            width: 0.9rem;
            height: 0.25rem;
            background: url("../assets/img/u912.png") no-repeat center;
            background-size: 80% 80%;
            border: 1px solid #fff;
            border-radius: 5px;
            background-color: #111;
            cursor: pointer;
          }
          .font-download-android {
            width: 0.9rem;
            height: 0.25rem;
            background: url("../assets/img/u913.png") no-repeat center;
            background-size: 80% 80%;
            border: 1px solid #fff;
            border-radius: 5px;
            background-color: #111;
            cursor: pointer;
          }
        }
      }
      .business-content-right {
        // flex: 1;
        position: relative;
        .business-content-right-bright {
          position: absolute;
          top: 0.4rem;
          right: 0.6rem;
          width: 1.7rem;
          height: 3.2rem;
          background: url("../assets/img/u361.png") no-repeat;
          background-size: 100% 100%;
          z-index: 2;
        }
        .business-content-right-dark {
          width: 1.4rem;
          height: 2.8rem;
          margin: 0.2rem 0.2rem 0 0;
          background: url("../assets/img/u360.png") no-repeat;
          background-size: 100% 100%;
        }
        .business-content-right-mode {
          position: absolute;
          width: 1.4rem;
          height: 2.8rem;
          top: 0.2rem;
          right: 0.2rem;
          background-color: rgba(0, 0, 0, 0.5);
          z-index: 1;
        }
      }
    }
  }
  .resource {
    width: 100%;
    // height: 5.4rem;
    background: url("../assets/img/output.gif") no-repeat;
    background-size: 100% 100%;
    border-top: 1px solid #fff;
    overflow: hidden;
    .resource-content {
      margin: 0.5rem 0.8rem;
      // height: 4rem;
      // background: red;
      display: flex;
      justify-content: space-between;
      .resource-content-left {
        // flex: 1;
        width: 2rem;
        margin-left: 0.2rem;
        .resource-content-left-title {
          font-size: 0.2rem;
          color: #fff;
        }
        .resource-content-left-info {
          margin-top: 0.4rem;
          color: #fff;
          font-size: 0.08rem;
          line-height: 28px;
        }
        .resource-content-left-contrast {
          margin-top: 0.4rem;
          color: #fff;
          font-size: 0.08rem;
          line-height: 28px;
        }
        .resource-content-left-img {
          margin-top: 0.4rem;
          width: 100%;
          height: 1.6rem;
          background: url("../assets/img/u371.png") no-repeat;
          background-size: 100% 100%;
        }
        .resource-content-left-download {
          margin-top: 0.5rem;
          width: 100%;
          display: flex;
          justify-content: space-between;
          .font-download-apple {
            width: 0.9rem;
            height: 0.25rem;
            text-align: center;
            line-height: 0.25rem;
            border: 1px solid #fff;
            border-radius: 5px;
            background-color: #111;
            color: #fff;
            cursor: pointer;
          }
          .font-download-android {
            width: 0.9rem;
            height: 0.25rem;
            text-align: center;
            line-height: 0.25rem;
            border: 1px solid #909399;
            border-radius: 5px;
            background-color: #111;
            color: #fff;
            cursor: pointer;
          }
          .font-download-android:hover {
            border: 1px solid #fff;
          }
        }
      }
      .resource-content-right {
        // flex: 1;
        position: relative;
        .resource-content-right-bright {
          position: absolute;
          top: 0.4rem;
          right: 0.6rem;
          width: 1.7rem;
          height: 3.2rem;
          background: url("../assets/img/u370.png") no-repeat;
          background-size: 100% 100%;
          z-index: 2;
        }
        .resource-content-right-dark {
          width: 1.4rem;
          height: 2.8rem;
          margin: 0.2rem 0.2rem 0 0;
          background: url("../assets/img/u360.png") no-repeat;
          background-size: 100% 100%;
        }
        .resource-content-right-mode {
          position: absolute;
          width: 1.4rem;
          height: 2.8rem;
          top: 0.2rem;
          right: 0.2rem;
          background-color: rgba(0, 0, 0, 0.5);
          z-index: 1;
        }
      }
    }
  }
  .Network {
    width: 100%;
    // height: 5.8rem;
    background: url("../assets/img/output.gif") no-repeat;
    background-size: 100% 100%;
    border-top: 1px solid #fff;
    overflow: hidden;
    .Network-content {
      margin: 0.5rem 0.8rem;
      // height: 4rem;
      // background: red;
      display: flex;
      justify-content: space-between;
      .Network-content-left {
        // flex: 1;
        width: 2rem;
        margin-left: 0.2rem;
        .Network-content-left-title {
          font-size: 0.2rem;
          color: #fff;
        }
        .Network-content-left-info {
          margin-top: 0.4rem;
          color: #fff;
          font-size: 0.08rem;
          line-height: 28px;
        }
        .Network-content-left-img {
          margin-top: 0.4rem;
          width: 100%;
          height: 2.6rem;
          background: url("../assets/img/u446.png") no-repeat;
          background-size: 100% 100%;
        }
        .Network-content-left-download {
          margin-top: 0.5rem;
          width: 100%;
          height: 0.25rem;
          text-align: center;
          line-height: 0.25rem;
          font-size: 0.06rem;
          border: 1px solid #fff;
          background-color: #111;
          color: #fff;
          border-radius: 5px;
          cursor: pointer;
        }
      }
      .Network-content-right {
        // flex: 1;
        position: relative;
        .Network-content-right-bright {
          position: absolute;
          top: 0.4rem;
          right: 0.6rem;
          width: 1.7rem;
          height: 3.2rem;
          background: url("../assets/img/u369.png") no-repeat;
          background-size: 100% 100%;
          z-index: 2;
        }
        .Network-content-right-dark {
          width: 1.4rem;
          height: 2.8rem;
          margin: 0.2rem 0.2rem 0 0;
          background: url("../assets/img/u360.png") no-repeat;
          background-size: 100% 100%;
        }
        .Network-content-right-mode {
          position: absolute;
          width: 1.4rem;
          height: 2.8rem;
          top: 0.2rem;
          right: 0.2rem;
          background-color: rgba(0, 0, 0, 0.5);
          z-index: 1;
        }
      }
    }
  }
  .coldplay {
    width: 100%;
    // height: 5.6rem;
    background: url("../assets/img/output.gif") no-repeat;
    background-size: 100% 100%;
    border-top: 1px solid #fff;
    overflow: hidden;
    .coldplay-content {
      margin: 0.5rem 0.8rem;
      // height: 4rem;
      // background: red;
      display: flex;
      justify-content: space-between;
      .coldplay-content-left {
        // flex: 1;
        width: 2rem;
        margin-left: 0.2rem;
        .coldplay-content-left-title {
          font-size: 0.2rem;
          color: #fff;
        }
        .coldplay-content-left-info {
          margin-top: 0.4rem;
          color: #fff;
          font-size: 0.08rem;
          line-height: 28px;
        }
        .coldplay-content-left-img {
          margin-top: 0.4rem;
          width: 100%;
          height: 2.6rem;
          background: url("../assets/img/u460.png") no-repeat;
          background-size: 100% 100%;
        }
        .coldplay-content-left-download {
          margin-top: 0.5rem;
          width: 100%;
          height: 0.25rem;
          text-align: center;
          line-height: 0.25rem;
          font-size: 0.06rem;
          border: 1px solid #fff;
          background-color: #111;
          color: #fff;
          border-radius: 5px;
          cursor: pointer;
        }
      }
      .coldplay-content-right {
        // flex: 1;
        position: relative;
        .coldplay-content-right-bright {
          position: absolute;
          top: 0.4rem;
          right: 0.6rem;
          width: 1.7rem;
          height: 3.2rem;
          background: url("../assets/img/u456.png") no-repeat;
          background-size: 100% 100%;
          z-index: 2;
        }
        .coldplay-content-right-dark {
          width: 1.4rem;
          height: 2.8rem;
          margin: 0.2rem 0.2rem 0 0;
          background: url("../assets/img/u360.png") no-repeat;
          background-size: 100% 100%;
        }
        .coldplay-content-right-mode {
          position: absolute;
          width: 1.4rem;
          height: 2.8rem;
          top: 0.2rem;
          right: 0.2rem;
          background-color: rgba(0, 0, 0, 0.5);
          z-index: 1;
        }
      }
    }
  }
  .download {
    width: 100%;  
    // height: 4.5rem;
    background-color: rgba(34, 34, 34, 1);
    .download-content {
      display: flex;
      // height: 100%;
      .download-content-left {
        width: 3rem;
        // height: 100%;
        margin: 0.5rem 0;
        display: flex;
        align-items: center;
        background-color: rgba(34, 34, 34, 1);
        .download-content-left-info {
          width: 2rem;
          // height: 3.5rem;
          margin: 0 auto;
          background-color: rgba(34, 34, 34, 1);
          color: #fff;
          .iconlogo {
            display: flex;
            align-items: center;
            .iconlogoimg {
              width: 0.15rem;
              height: 0.15rem;
            }
            .iconlogotext {
              font-size: 0.11rem;
              color: #fff;
              margin-left: 0.04rem;
            }
          }
          .download-content-left-introduce {
            margin-top: 0.15rem;
            font-size: 0.07rem;
            line-height: 28px;
          }
          .download-content-left-mode {
            display: flex;
            justify-content: space-between;
            margin-top: 0.4rem;
            .download-content-left-mode-apple {
              width: 0.9rem;
              height: 0.25rem;
              background: url("../assets/img/u912.png") no-repeat center;
              background-size: 80% 80%;
              border: 1px solid #fff;
              border-radius: 5px;
              background-color: #111;
              cursor: pointer;
            }
            .download-content-left-mode-android {
              width: 0.9rem;
              height: 0.25rem;
              background: url("../assets/img/u913.png") no-repeat center;
              background-size: 80% 80%;
              border: 1px solid #fff;
              border-radius: 5px;
              background-color: #111;
              cursor: pointer;
            }
          }
        }
      }
      .download-content-right {
        position: relative;
        flex: 1;
        background: url("../assets/img/u379.png") no-repeat;
        background-size: 100% 100%;
        .download-content-right-mode {
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: #111;
          opacity: 0.5;
        }
      }
    }
  }
}
</style>
<style scoped>
/* .business-content-left-table >>> .el-table__row{
  background-color: #111 !important;
  color: #fff !important;
}
.business-content-left-table >>>.has-gutter{
 background-color: #111 !important;
  color: red!important;
} */
</style>