const mixin = {
    data() {
      return{
      }
    },
    watch: {
        "$route.params.id": function (newValue) {
            this.switchFilter(newValue)
          
        },
      },
    methods:{
        jump(type) {
            var id = "#"+ type + this.$route.params.id;
            this.$nextTick(()=>{
              document.querySelector(id).scrollIntoView({
              behavior: "smooth",
              block: "start",
              inline: "nearest",
            });
            })
          },
        switchFilter(value){
            switch(value.substring(0,1)){
                case'o':
                this.jump('one')
                break;
                case't':
                this.jump('two')
                break;
                case'T':
                this.jump('Three')
                break;
                case'f':
                this.jump('four')
                break;
                case'F':
                this.jump('Five')
                break;
                default:
                break;
        }
        }
    },
    created() {
    },
    mounted(){
        this.switchFilter(this.$route.params.id)    
  }
}
  export default mixin